import * as React from 'react'

export const About = () => (
  <React.Fragment>
    <h3>About me</h3>
    <section className="about-me">
      I'm a Python Developer who spends most of my free time developing in Python/NodeJS/ReactJS and also
      dabbling with Virtual Environments via Proxmox and XenServer for my HomeLab.  I have a few VMs for controlling my DNS, OpenVPN and 
      Unifi Controller for my Unifi Wireless Access Points.  Another VM acts as my Docker Environment hosting my Jenkins and MongoDB containers.
    </section>
  </React.Fragment>
);