import React from 'react';
import './App.css';

import { Header } from './components/Header';
import { About } from './components/About'

function App() {
  return (
    <div className="App"> 
      <header className="App-header">
        <Header />
        <About />
        <br />
        <ul className="social">
          <li><a href="https://github.com/ronpichardo" target="_blank" rel="noreferrer"><span className="fa fa-github"></span></a></li>
          <li><a href="https://www.linkedin.com/in/ronaldy-pichardo" target="_blank" rel="noreferrer"><span className="fa fa-linkedin"></span></a></li>
        </ul> 
      </header>
    </div>
  );
}

export default App;
